

















import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/components/form/fields/abstract-field'

  @Component
export default class DeleteActionField extends AbstractField {}
